<template>
  <div class="relative w-screen h-full">
    <div class="fixed top-8 right-8 z-50 w-full w-full h-16">
      <button class="back-btn"  @click="()=> this.$router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
          <defs>
            <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_12962" data-name="Group 12962" transform="translate(-308 -28)">
            <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
              <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                  <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                </g>
              </g>
            </g>
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
          </g>
        </svg>

      </button>
    </div>
      <mapir
          style="width: 100%; height: 100%;"
          :apiKey="token"
          :mapStyle="options.style"
          :center="coordinates"
          :zoom="zoom"
          @click="moveMap"
      >
        <mapGeolocateControl position="bottom-right" />
        <mapNavigationControl position="bottom-right" />
        <mapMarker
            :coordinates="coordinates"
            color="blue"
            :draggable="true"
            @dragend="getlatLng"
        ><template slot="marker">
          <img class="w-10 h-10 rounded-full" src="../../assets/images/icons/location-pin.svg" alt="">
        </template>
        </mapMarker>
      </mapir>
    <a class="btn-custom btn-map w-5/6 left-0 mx-auto bottom-20" @click="handleSelectLocation">
      ثبت موقعیت و ادامه
      <span class="svg-btn-icon2 bg-orange transform rotate-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
                </span>
    </a>
  </div>
</template>

<script>

import {mapir, mapMarker , mapNavigationControl, mapGeolocateControl} from "mapir-vue";
import axiosMap from "../../plugins/mapAxios";
export default {
  name: "EditEstateMap",
  components: {
    mapir,
    mapMarker,
    mapNavigationControl,
    mapGeolocateControl
  },
  data() {
    return {
      coordinates: [],
      token : this.$store.state.map.token,
      options : this.$store.state.map.options,
      zoom : 10,
      baseURL : this.$store.state.general.baseURL,
    }
  },
  computed :{

  },
  methods :{
    moveMap({actualEvent}) {
      var lngLat = actualEvent.lngLat;
      this.coordinates = [lngLat.lng , lngLat.lat ]
    },
    getlatLng({component, map, marker}) {
      this.markerCoordinates = [marker['_lngLat'].lat,marker['_lngLat'].lng]
      this.coordinates = [marker['_lngLat'].lng, marker['_lngLat'].lat]
    },
    handleSelectLocation(){
      axiosMap.get('/fast-reverse',{
        params:{
          lat : this.coordinates[1],
          lon : this.coordinates[0]
        }
      }).then(({data})=>{
        const address = {
          geoposition : {
            latitude : data.geom.coordinates[1],
            longitude : data.geom.coordinates[0]
          },
          city : data.county,
          province : data.province,
          address : data.district,
          area :  data.neighbourhood
        }
        const crd = {
          lat : data.geom.coordinates[1],
          lon : data.geom.coordinates[0]
        }
         this.$store.commit('map/SET_SELECT_LOCATION', crd)
         this.$store.commit('editEstate/SET_ADDRESS' , address);
        this.$router.push({name : 'EditEstateStep4'})

      })
    }
  },
   async mounted() {
    let crd = await this.$store.state.map.selectLocation
    if (crd.lat !== null && crd.lon !== null){
      this.coordinates = [crd.lon , crd.lat]
    }else {
      this.$toast.error('لطفا منطقه خود را انتخاب کنید')
      this.$router.push({name : 'EditEstateStep4'})
    }
    this.$store.commit('general/CHANGE_IS_SHOW_BACK' , false)
  },
  created() {

  },
  beforeDestroy() {
    this.$store.commit('general/CHANGE_IS_SHOW_BACK' , true)
    this.$store.commit('map/CLEAR_AREA')
  }
}
</script>

<style >

</style>